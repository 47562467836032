(function($, window) {
    $.fn.bootstrapPasswordMeter = function(options) {
        var settings = $.extend({
            minPasswordLength: 6,
            level0ClassName: 'uk-label-danger',
            level0Description: 'Unsicher',
            level1ClassName: 'uk-label-danger',
            level1Description: "Unsicher",
            level2ClassName: 'uk-label-warning',
            level2Description: 'Geht so',
            level3ClassName: 'uk-label-warning',
            level3Description: 'Sicher',
            level4ClassName: 'uk-label-warning',
            level4Description: 'Sehr Sicher!',
            parentContainerClass: '.form-password'
        }, options || {});

        $(this).on("keyup", function() {
            var progressBar = $(this).closest(settings.parentContainerClass).find('.progress-bar');
            var progressBarLabel = $(this).closest(settings.parentContainerClass).find('.progress-label');
            var progressBarLength = $(this).closest(settings.parentContainerClass).find('.progress-length');
            var progressBarWidth = 0;
            var progressBarDescription = '';
            if ($(this).val().length >= settings.minPasswordLength) {
                var zxcvbnObj = zxcvbn($(this).val());
                progressBarLabel.removeClass(settings.level0ClassName)
                    .removeClass(settings.level1ClassName)
                    .removeClass(settings.level2ClassName)
                    .removeClass(settings.level3ClassName)
                    .removeClass(settings.level4ClassName);
                switch (zxcvbnObj.score) {
                    case 0:
                        progressBarWidth = 25;
                        progressBarLabel.addClass(settings.level0ClassName);
                        progressBarDescription = settings.level0Description;
                        break;
                    case 1:
                        progressBarWidth = 25;
                        progressBarLabel.addClass(settings.level1ClassName);
                        progressBarDescription = settings.level1Description;
                        break;
                    case 2:
                        progressBarWidth = 50;
                        progressBarLabel.addClass(settings.level2ClassName);
                        progressBarDescription = settings.level2Description;
                        break;
                    case 3:
                        progressBarWidth = 75;
                        progressBarLabel.addClass(settings.level3ClassName);
                        progressBarDescription = settings.level3Description;
                        break;
                    case 4:
                        progressBarWidth = 100;
                        progressBarLabel.addClass(settings.level4ClassName);
                        progressBarDescription = settings.level4Description;
                        break;
                }
            } else {
                progressBarWidth = 0;
                progressBarDescription = '';
            }

            progressBar.val(progressBarWidth);
            progressBarLabel.text(progressBarDescription);
            progressBarLength.text($(this).val().length);
        });
    };







})(jQuery, window);
(function ($, window) {

    $(".userAccountEditDate").on('change', function () {
        var form = $(this).closest("form");
        form.find('.control').val('change');
        form.submit();
    });


    $(".editFormButton").click(function (e) {
        e.preventDefault();
        var formContainerDivSelector = '#' + $(this).attr('id') + 'Frm';
        var allForContainersSelector = '.editFormContainer';
        $(allForContainersSelector + ':not(' + formContainerDivSelector + ')').hide();
        $(formContainerDivSelector).toggle();
    });
    $("li.has--items[data-state]").click(function (e) {
        //console.log($(this).attr('data-state'));
        //$('li.has--items[data-state]').attr("data-state","inactive");
        $(this).attr('data-state', $(this).attr('data-state') == 'inactive' ? 'active' : 'inactive');


    });
    var emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;


})(jQuery, window);